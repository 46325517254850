import React, { Component, useState } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import useForm from "react-hook-form";
import * as yup from "yup";
import Select from "react-select";
@inject("entityStore","notifyStore")
@observer
class FormRicerca extends Component {
  state = { classi: [], categorie: [], titoli: [], loading: true };
  componentDidMount = async () => {
    const store = this.props.entityStore;
    try {
      await store.getAllEntity("");
      this.setState({
        classi: store.classi.map(item => ({
          value: "" + item.Id,
          label: item.Nome
        })),
        titoli: store.titoli.map(item => ({
          value: "" + item.Id,
          label: item.Descrizione
        })),
        categorie: store.categorie.map(item => ({
          value: "" + item.Id,
          label: item.Nome
        })),
        loading: false
      });
    } catch (e) {
      this.setState({loading: false})
      this.props.notifyStore.notify("ERROR","ci sono stati dei problemi con i servizi si prega di riaccedere")
      
    }
  };
  render() {
    let data = this.props.entityStore;
    if (this.state.loading) return null;
    return (
      <Iform
        store={data}
        classi={this.state.classi}
        titoli={this.state.titoli}
        categorie={this.state.categorie}
      />
    );
  }
}
const customStyles = {
  control: styles => ({ ...styles, maxWidth: 400 })
};
const crudSchema = yup.object().shape({
  classi: yup.string().required(),
  titoli: yup.string().required()
});
function Iform(props) {
  const [store, setStore] = useState(props.store);
  const [classi, setClassi] = useState(props.classi);
  const [titoli, setTitoli] = useState(props.titoli);
  const [categorie, setCategorie] = useState(props.categorie);

  let { register, handleSubmit, reset } = useForm({
    //   validationSchema: crudSchema
  });
  const onSubmit = () => {
    store.search();
  };
  const onChangeCat = async data => {
    crudSchema.default();
    await store.getAllSTitoli(data.value);
    setTitoli(
      store.titoli.map(item => ({
        value: "" + item.Id,
        label: item.Descrizione
      }))
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="col-md-3 col-lg-3 form-group has-feedback">
        <label>Classi</label>
        <Select
          closeMenuOnSelect={false}
          placeholder="Seleziona le classi"
          required
          ref={register}
          name={"classi"}
          isMulti
          onChange={value => store.changeProps("Classi", value)}
          options={classi && classi.constructor === Array ? classi : []}
        />
        <span className=" form-control-feedback" aria-hidden="true" />
        <div className="help-block with-errors" />
      </div>
      <div className={" col-md-3 col-lg-3 form-group has-feedback "}>
        <label>Categorie</label>
        <Select
          closeMenuOnSelect={true}
          placeholder="Seleziona le Categorie"
          required
          ref={register}
          name="categorie"
          defaultValue={categorie[2]}
          isSearchable={true}
          isClearable={true}
          onChange={onChangeCat}
          options={
            categorie && categorie.constructor === Array ? categorie : []
          }
        />
        <span className=" form-control-feedback" />
        <div className="help-block with-errors" />
      </div>
      <div className={" col-md-3 col-lg-3 form-group has-feedback "}>
        <label>Titoli</label>
        <Select
          styles={customStyles}
          closeMenuOnSelect={false}
          placeholder="Seleziona i Titoli"
          required
          name={"titoli"}
          isMulti
          isSearchable={true}
          isClearable={true}
          onChange={value => store.changeProps("Titoli", value)}
          options={titoli && titoli.constructor === Array ? titoli : []}
        />
        <span className=" form-control-feedback" />
        <div className="help-block with-errors" />
      </div>
      <div className="col-md-3 col-lg-3 form-group has-feedback ">
        <label>&nbsp;</label>
        <br></br>
        <button type="submit" className="btn  btn-default btn-block">
          <i className="fa fa-search"></i>
          Ricerca Candidati
        </button>
      </div>
    </form>
  );
}
FormRicerca.propTypes = {
  crudStore: PropTypes.object
};
Iform.propTypes = {
  store: PropTypes.object
};
export default FormRicerca;
