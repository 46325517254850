//import $ from "jquery";
import React, { useRef, useState, useEffect } from "react";

function Modal({ component, title, show, closeModal, button }) {
  const modal = useRef(null);
  useState({ component, title, closeModal, button });
  const [_show, _setShow] = useState(show);

  useEffect(() => {
    _setShow(show);
  }, [show]);

  return (
    <div className={"modal " + (_show ? "show" : "")} ref={modal}>
      <div className="modal-dialog  modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title">{title}</h4>
          </div>
          <div className="modal-body" style={{ overflowY: "scroll" }}>
            {component}
          </div>
          <div className="modal-footer">{button}</div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
