import { observable, action, reaction } from "mobx";
//import agent from "../agent";
const AUTH_KEY = "AUTH_KEY";

class Config {
  bkColor = "white";
  giorni_lavorativi = [2, 6];
  fascia_oraria = ["08:00", "20:00"];
  pausa_pranzo = ["12:00", "13:00"];
  @observable aperture = {
    "1": null,
    "2": null,
    "3": null,
    "4": null,
    "5": null,
    "6": null,
    "7": null
  };
  @observable email_user = undefined;
  client_id = undefined;
  rtoken = undefined;
  atoken = undefined;
  csecret = undefined;
  @observable sms_user = undefined;
  @observable sms_pwd = undefined;
  password = undefined;
  data_create = undefined;
  dow = undefined;
  @observable alias = undefined;
  @observable webapp = undefined;
}
class CommonStore {
  @observable loadingCfg = true;
  appName = "IPrenotation";
  @observable token = localStorage.getItem(AUTH_KEY);
  @observable appLoaded = false;
  config = new Config();

  @observable message = undefined;
  @observable error = undefined;

  constructor() {
    reaction(
      () => this.token,
      token => {
        if (token) {
          localStorage.setItem(AUTH_KEY, token);
        } else {
          localStorage.removeItem(AUTH_KEY);
        }
      }
    );
  }

  @action getToken() {
    return localStorage.getItem(AUTH_KEY);
  }

  @action onConfig = async () => {
    this.loadingCfg = true;
    try {
      const res = {}; // = await agent.Cmn.config();
      if (res.config.length > 0) this.config = res.config[0];
      this.loadingCfg = false;
    } catch (e) {
      this.loadingCfg = false;
    }
  };
  @action changeProps = (prop, value) => {
    this.loadingCfg = true;
    this.config[prop] = value;
    this.loadingCfg = false;
  };

  @action changeAperture = (name, value) => {
    this.loadingCfg = true;
    this.config.aperture[name] = value;
    this.loadingCfg = false;
  };

  @action onInsert = async () => {
    try {
      this.loadingCfg = true;
      const res = await agent.Cmn.onInsert({ element: this.config });
      this.message = res.message;
      this.loadingCfg = false;
    } catch (e) {
      this.error = "Errore durante il salvataggio della configurazione!";
      this.loadingCfg = false;
    }
    this.timeout();
  };

  @action getConfig() {
    return this.config;
  }

  @action setToken(token) {
    this.token = token;
  }

  @action setAppLoaded() {
    this.appLoaded = true;
  }
  timeout = () => {
    let self = this;
    setTimeout(() => {
      self.error = undefined;
      self.message = undefined;
    }, 1500);
  };
}

export default new CommonStore();
