import React, { Component } from "react";
import { Redirect } from "react-router-dom";
//import $ from "jquery";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import useForm from "react-hook-form";
import * as yup from "yup";
@inject("authStore")
@observer
class LogIn extends Component {

  render() {
    let err, msg;
    let data = this.props.authStore;

    if (data.isLogged) {
      return <Redirect to="/home" />;
    } else {
      if (data.errors)
        err = (
          <div className="alert alert-danger" role="alert">
            <b>{data.errors}</b>
          </div>
        );
      if (data.msg)
        msg = (
          <div className="callout callout-warning" role="alert">
            <b>{data.msg}</b>
          </div>
        );
      return (
        <div >
          <p
            className="text-center"
            style={{ fontSize: 20, color: "black", fontWeight: 300 }}
          >
            <strong>A</strong>ccedi
          </p>
          {err}
          {msg}
          <Iform store={data} />
        </div>
      );
    }
  }
}
const SignupSchema = yup.object().shape({
  email: yup
    .string()
    .required("Campo email richiesto!")
    .email("Formato Email non valido"),
  password: yup
    .string()
    .required("Campo password richiesto!")
    .min(6,"La password deve avere minimo 6 caratteri")
  
});
function Iform(props) {
  const store = props.store;
  let { register, handleSubmit, errors } = useForm({
    validationSchema: SignupSchema
  });
  const onSubmit = data => {
    SignupSchema.default();
    store.login(data);
  }; // callback when validation pass

  return (
    <form>
      <ListError data={errors} />
      <div className="form-group"></div>
      <div className="form-group has-feedback">
        <input
          type="email"
          className="form-control-login"
          placeholder="Email"
          name="email"
          ref={register}
          required
          //value={data.values.email || ""}
          // onChange={this.handleChange}
        />
        <span
          className="fa fa-envelope form-control-feedback"
          aria-hidden="true"
        />
        <div className="help-block with-errors" />
      </div>
      <div className="form-group has-feedback">
        <input
          type="password"
          className="form-control-login input-md"
          placeholder="Password"
          name="password"
          data-maxlength="14"
          data-minlength="6"
          required
          ref={register}
        />
        <span className="fa fa-lock form-control-feedback" />
        <div className="help-block with-errors" />
      </div>
      <div className="form-group">
        <button
          type="button"
          className="btn bg-navy btn-block "
          onClick={handleSubmit(onSubmit)}
        >
          Entra
        </button>
      </div>
    </form>
  );
}
function ListError(props) {
  const elems = props.data;
  const keys = Object.keys(props.data);

  if (keys.length > 0) {
    const list = keys.map((key, index) => (
      <p key={index}>{elems[key].message}</p>
    ));
    return (
      <div className="alert alert-danger" role="alert">
        <h4>
          <i className="icon fa fa-ban"></i> Attenzione!
        </h4>
        {list}
      </div>
    );
  } else return null;
}
LogIn.propTypes = {
  authStore: PropTypes.object
};

export default LogIn;
