import { observable, action } from "mobx";
import { Auth } from "../agent";

class Signin {
  @observable email = undefined;
  @observable password = undefined;
  @observable passwordbis = undefined;
}
class Account {
  @observable name = undefined;
  @observable descrizione = undefined;
  @observable rs = undefined;
  @observable indirizzo = undefined;
  @observable pi_cf = undefined;
  @observable cap = undefined;
  @observable tipologia = undefined;
  @observable dominio = undefined;
  @observable telefono = undefined;
}
class QrCode {
  @observable dominio = undefined;
  @observable webapp = undefined;
  @observable esercizio = undefined;
}
class Profile {
  qrcode = new QrCode();
  account = new Account();
  signin = new Signin();
}
class UserStore {
  @observable profileLoading = true;
  @observable formUpdate = true;
  @observable message = undefined;
  @observable currentUser;
  @observable loadingUser;
  @observable updatingUser;
  @observable updatingUserErrors;
  profile = new Profile();

  @action pullUser(user) {
    this.loadingUser = true;
    this.currentUser = user;
    this.loadingUser = false;
  }

  @action updateUser(newUser) {
    this.updatingUser = true;
    return agent.Auth.save(newUser)
      .then(
        action(({ user }) => {
          this.currentUser = user;
        })
      )
      .finally(
        action(() => {
          this.updatingUser = false;
        })
      );
  }

  @action forgetUser() {
    this.currentUser = undefined;
  }
  @action onChangeSignin(name, value) {
    this.formUpdate = true;
    this.profile.signin[name] = value;
    this.formUpdate = false;
  }
  @action onProfile = async () => {
    try {
      this.profileLoading = true;
      const res = await agent.Auth.onProfile();
      this.profile.signin = res.user;
      this.profile.signin.password = undefined;
      this.profile.signin.password_re = undefined;
      this.profile.account = res.acc;
      this.profile.qrcode.dominio = res.acc.dominio;
      this.profileLoading = false;
    } catch (e) {
      this.profileLoading = false;
    }
  };
  @action onResetDsk = async () => {
    try {
      const res = await agent.Auth.onReset(this.profile.signin);
      this.profile.signin = new Signin();
      this.message = res.message;
    } catch (e) {
      this.profileLoading = false;
    }
  };
}

export default new UserStore();
