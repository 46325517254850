import React, { Component } from "react";
import {FormLogIn} from "../forms/index";
import Infos from "../utils/Infos";
import Logo from "../utils/Logo";
import Settings from "../config/Settings";
import Loading from "../utils/Loading";
import { inject, observer } from "mobx-react";

@inject("authStore")
@observer
class HomeSection extends Component {
  componentDidMount = async () => {
    await this.props.authStore.isAuth();
  };
  render() {
    if (this.props.authStore.inProgress)
      return (
        <div className="overlay ">
          <Loading />
        </div>
      );
    else
      return (
        <div
          className="row"
          style={{
            height: "100%",
            width: "100%",
            marginRight: 0,
            marginLeft: 0
          }}>
          <div className="hidden-xs hidden-sm col-md-8 col-lg-9 mx-auto" style={{ padding: 0, height: "100%" }}>
            <header
              className="masthead text-white text-center"
              style={{
                height: "100%",
                background: "url('" + process.env.PUBLIC_URL + Settings.img.centro + "') ",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"
              }}>
              <div className="container">
                <div className="mx-auto">
                  <h1 className="mb-5 text-white text-center">&nbsp;</h1>
                </div>
              </div>
            </header>
          </div>
          <div className="col-md-4 col-lg-3 mx-auto">
            <div className="lockscreen-wrapper ">
              <div className="lockscreen-logo">
                <Logo />
              </div>
              <div style={{ padding: 15 }}>
              <FormLogIn />
              <Infos />
              </div>
            </div>
          </div>
        </div>
      );
  }
}

export default HomeSection;
