import { Api, Notify } from "../agent";

import { observable, computed, action } from "mobx";

class CrudStore {
  @observable message = undefined;
  @observable error = undefined;
  @observable loadingList = true;
  @observable loading = true;
  elems = [];
  elemForm = undefined;
  selected = undefined;

  @action
  select = id => {
    this.selected = id;
  };

  @action
  changeProps = (prop, value) => {
    this.elemForm[prop] = value;
  };

  @action
  update = value => {
    this.elemForm = value;
  };
  @action
  reset = () => {
    this.elemForm = undefined;
  };

  arrayAdd(elem) {
    this.elems.push(elem);
  }
  arrayDel(elem) {
    const index = this.elems.findIndex(q => q._id === elem._id);
    this.elems.splice(index, 1);
  }
  arrayMod(elem) {
    const index = this.elems.findIndex(q => q._id === elem._id);
    this.elems[index] = elem;
  }

  @action
  add = async (base, form) => {
    try {
      const res = await Api.POST(base + "/add", form);
      this.message = res.message;
      if (this.elemForm._id !== undefined) this.arrayMod(res.element);
      else this.arrayAdd(res.element);
      this.reset();
      Notify.notify("SUCCESS", this.message);
    } catch (e) {
      this.error = "Errore";
    }
  };

  @action
  delete = async id => {
    try {
      const res = await Api.POST(base + "/delete", id);
      this.message = res.message;
      this.arrayDel(res.element);
      this.selected = undefined;
      Notify.notify("SUCCESS", this.message);
    } catch (e) {
      this.selected = undefined;
    }
  };
  @action
  allItems = async base => {
    try {
      this.loadingList = true;
      const res = await await Api.GET(base + "/all");
      this._elems = res.elements;
      this.loading = false;
      this.loadingList = false;
    } catch (e) {
      this.loading = false;
      this.loadingList = false;
      this.error = "Errore";
    }
  };
  @action
  ItemById = async (base, id) => {
    try {
      this.loadingList = true;
      const res = await await Api.GET(base + "/" + id);
      this.elemForm = res.element;
      this.loading = false;
      this.loadingList = false;
    } catch (e) {
      this.loading = false;
      this.loadingList = false;
      this.error = "Errore";
    }
  };
}

export default new CrudStore();
