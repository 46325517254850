import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";

@inject("entityStore")
@observer
class Detail extends Component {
  render() {
    const data = this.props.entityStore.candidato;
    const store = this.props.entityStore;
    if (store.showDetail)
      return (
        <div className="row" ref={this.props.inputRef}>
          <div className="col-md-12 text-center">
            <h3>
              {data.Nome} {data.Cognome}
            </h3>
          </div>
          <div className="col-md-12">
            <h4>
              {(
                "Tel.: " +
                data.Tel +
                " Cell.: " +
                data.Cell +
                " Email: " +
                data.Email +
                " Indirizzo: " +
                data.IndirizzoResidenza +
                " " +
                data.ComuneResidenza +
                "(" +
                data.ProvResidenza +
                ")" +
                " C.F " +
                data.CodiceFiscale
              )
                .replace(/undefined/g, "")
                .replace(/null/g, "")}
            </h4>
          </div>
          <div className="col-md-12">
            <strong>Classi Concorso</strong>
            <hr className="borderPurple"></hr>
            <ul>
              <li>
                {data.Classi.map((item, i) => (
                  <span key={i + "c"}>{item.Nome}&nbsp;&nbsp;</span>
                ))}
              </li>{" "}
            </ul>
          </div>
          <div className="col-md-12">
            <strong>Titoli Studio</strong>
            <hr className="borderPurple"></hr>
            <ul>
              {data.Titoli.map((item, i) => (
                <li key={i + "t"}>
                  {item.Nome}&nbsp;&nbsp;{item.Data}&nbsp;&nbsp;{item.Voto}&nbsp;&nbsp;{item.Luogo}&nbsp;&nbsp;{item.Categoria}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-12">
            <strong>Titoli Candidato</strong>
            <hr className="borderPurple"></hr>
            <ul>
              {data.Complementari.map((item, i) => (
                <li key={i + "cc"}>{(item.Nome ? item.Nome : item.StrTipo + " " + item.Data + " " + item.Luogo).replace(/undefined/g, "").replace(/null/g, "")}</li>
              ))}
            </ul>
          </div>
        </div>
      );
    return null;
  }
}
Detail.propTypes = {
  entityStore: PropTypes.object,
};
export default Detail;
