class Settings {
  constructor() {
    this.name = "MAD";
    this.studio = "Gest. MAD 2.0";
    this.telefono = "*********";
    this.indirizzo = "";
    this.color = "#c6217f";
    this.img = {
      vuoto: "img/vuoto.webp",
      logo: "img/logo.png",
      centro: "img/school2.webp"
    };
    this.scadenza = {
      cloud: "31/01/2019",
      ios: "31/06/2019"
    };
  }
}

export default new Settings();
