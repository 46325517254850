import React from "react";
import { Link } from "react-router-dom";
import Logo from "../utils/Logo";
function HomePage() {
  return (
    <section className="content">
      <div className="box box-default">
        <div className="box-header with-border">
          <h4 className="box-title">Home page</h4>
        </div>
        <div className="box-body">
          <div className="row">
            <div className="col-md-12">
              <Logo width="10%" />
            </div>
            <div className="col-md-12">
              <div style={{ textAlign: "center", color: "gray" }}>
                <h3>Benvenuti!</h3>
                <i className="fa fa-handshake-o fa-4x"></i>
                <p> nel portale a voi dedicato per la ricerca dei candidati</p>
                <p> per iniziare clicca il link ricerca</p>
                <h4>
                  <Link to="/search" style={{ color: "#c6217f" }}>
                    <i className="fa fa-search" />
                    Ricerca
                  </Link>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomePage;
