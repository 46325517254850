import React, { Component } from "react";
import Loading from "./utils/Loading";
import history from "./history";
import Settings from "./config/Settings";
import { Router, Route } from "react-router-dom";
import { Home } from "./pages";
import { _GestMad,_HomePage,_ConfigPage } from "./layout/Routes";
import Logo from "./utils/Logo"
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }
  async componentDidMount() {
    await import("./App.min.css");
    await import("./skin.min.css");
    await import("./index.min.css");
    this.setState({
      loading: false
    });
  }
  render() {
 
    if (this.state.loading)
    return (
      <div className="ipl-progress-indicator">
        <div className="ipl-progress-indicator-head">
          <div className="first-indicator" />
          <div className="second-indicator" />
        </div>
        <div className="insp-logo-frame text-center" style={{ display: "block", margin: "auto", marginTop: "10%" }}>
          <Logo width="5%"/>
          <Loading />
          <h3 style={{ color: Settings.color }}> {Settings.studio}</h3>
        </div>
      </div>
    );
    return (
      <Router history={history}>
        <div className="rootCenter"  >
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={_HomePage} />
          <Route exact path="/search" component={_GestMad} />
          <Route exact path="/config" component={_ConfigPage} />
        </div>
      </Router>
    );
  }
}
export default App;
