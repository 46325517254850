import React from "react";
import Layout from "./Layout";
import { Dash, GestMad,HomePage,ConfigPage } from "../pages";
//const CDash = React.lazy(() => import("../pages/Dash"));

const _Dash = () => <Layout component={<Dash />} sideBar={false} footer={false} />;
const _GestMad = () => <Layout component={<GestMad />} sideBar={false} footer={false} />;
const _HomePage = () => <Layout component={<HomePage />} sideBar={false} footer={false} />;
const _ConfigPage = () => <Layout component={<ConfigPage />} sideBar={false} footer={false} />;
export { _Dash, _GestMad ,_HomePage,_ConfigPage};
