import { observable, action } from "mobx";

class NotifyStore {
  @observable message = undefined;
  @observable type = undefined;

  @action notify = (type, message) => {
    this.message = message;
    this.type = type;
    this.timeout();
  };

  timeout = () => {
    let self = this;
    setTimeout(() => {
      self.type = undefined;
      self.message = undefined;
    }, 2000);
  };
}
export default new NotifyStore();
export { NotifyStore };
