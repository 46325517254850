import { observable, action } from "mobx";
import { Auth, Notify } from "../agent";
import userStore from "./userStore";
import commonStore from "./commonStore";
import history from "../history";
class AuthStore {
  @observable inProgress = false;
  @observable errors = undefined;
  @observable msg = undefined;
  @observable isLogged = false;
  @observable code = localStorage.getItem("CODE");
  @observable
  values = {
    username: undefined,
    email: undefined,
    password: undefined,
    code: undefined
  };

  @action
  changeProps = (prop, value) => {
    this.values[prop] = value;
  };

  @action
  setPassword(password) {
    this.values.password = password;
  }

  @action
  reset() {
    this.values.username = "";
    this.values.email = "";
    this.values.password = "";
    this.values.code = "";
  }

  @action
  async isAuth() {
    this.inProgress = true;
    this.errors = undefined;
    try {
      const res =commonStore.getToken()
      if (res) {
        userStore.pullUser(res);
        this.isLogged = true;
      }
    } catch (err) {
      this.isLogged = false;
      this.msg = "Fornire le credenziali.";
      this.inProgress = false;
      this.timeout();
    }
    this.inProgress = false;
  }

  @action
  async login(data) {
    this.inProgress = true;
    this.errors = undefined;
    try {
      let user = {email:data.email,token:""}
      const token=await Auth.login(data.email,data.password,'')
      commonStore.setToken(token.access_token);
      user.email = "";
      userStore.pullUser(user);
      this.reset();
      this.isLogged = true;
    } catch (e) {
      this.isLogged = false;
      this.errors = "Email o Password Errati, Riprova!";
      this.inProgress = false;
      this.timeout();
    }
    this.inProgress = false;
  }

  @action
  logout() {
    commonStore.setToken(undefined);
    userStore.forgetUser();
    this.isLogged = false;
    history.push("/");
    return Promise.resolve();
  }

  timeout = () => {
    let self = this;
    setTimeout(() => {
      self.errors = undefined;
      self.msg = undefined;
    }, 1500);
  };
}

export default new AuthStore();
