import React, { Component, useState,useMemo } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import useForm from "react-hook-form";
import * as yup from "yup";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment"
@inject("entityStore")
@observer
class FormInvio extends Component {
  render() {
    let store = this.props.entityStore;
    return (
      <div className="row">
        {store.show}
        <div className="col-lg-12">
          <Iform store={store} candidati={store.form.Candidati} />
        </div>
      </div>
    );
  }
}
const crudSchema = yup.object().shape({
  classi: yup.string().required(),
  titoli: yup.string().required()
});
function Iform({store,candidati}) {

  const [state, setState] = useState(
    {
      value:"Seleziona Date"
    }
  );
  const [_candidati, _setCandidati] = useState(candidati);

  React.useEffect(() => {
    console.log(candidati)
    _setCandidati(candidati);
  }, [candidati]);
  
  let { register, handleSubmit, reset } = useForm({
    validationSchema: crudSchema
  });
  const onSubmit = data => {
    crudSchema.default();
    store.add("/search", data);
  };
  
  const handleEvent =(event, picker) =>{
    setState({value:picker.startDate.format("ll")+"-"+picker.endDate.format("ll")})
  }
  const remCandidato =(id) =>{
    store.removeCandidato(id)
    _setCandidati(store.form.Candidati)
  }
  if (_candidati.length > 0)
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="col-lg-4 form-group has-feedback">
          <label>Durata*</label>
          <br />
          <DateRangePicker  onApply={handleEvent} minDate={moment()} startDate={moment()} showCustomRangeLabel={true} >
            <button className="btn btn-block btn-social btn-default">
            <i className="fa fa-calendar-plus-o"></i> {state.value}
            </button>
          </DateRangePicker>
          <span className=" form-control-feedback" aria-hidden="true" />
          <div className="help-block with-errors" />
        </div>
        <div className={" col-lg-4 form-group has-feedback "}>
          <label>Metodo invio*</label>
          <Select
            closeMenuOnSelect={false}
            placeholder="Seleziona"
            required
            name="metodo"
            isMulti
            options={[
              { value: "1", label: "Email" },
              { value: "2", label: "SMS" }
            ]}
          />
          <span className=" form-control-feedback" />
          <div className="help-block with-errors" />
        </div>
        <div className={" col-lg-4 form-group has-feedback "}>
          <label>Ore*</label>
          <Select
            closeMenuOnSelect={true}
            placeholder="Seleziona"
            required
            name="metodo"
            isMulti={false}
            options={[
              { value: "1", label: "1H" },
              { value: "2", label: "2H" },
              { value: "3", label: "3H" },
              { value: "4", label: "4H" },
              { value: "1", label: "5H" },
              { value: "5", label: "6H" },
              { value: "6", label: "7H" },
              { value: "7", label: "8H" },
              { value: "8", label: "9H" },
              { value: "9", label: "10H" },
              { value: "10", label: "11H" },
              { value: "11", label: "12H" }
            ]}
          />
          <span className=" form-control-feedback" />
          <div className="help-block with-errors" />
        </div>
        <div className={" col-lg-12 form-group has-feedback "}>
          <label>Testo Candidatura</label>
          <textarea
            cols={4}
            rows={4}
            className="form-control"
            placeholder="Enter Testo messaggio"
          />
        </div>
        <div className={"col-lg-12 form-group"}>
          <label>Elenco Candidati</label>
          <br />
          <div
            style={{
              overflowY: "scroll",
              height: "100px"
            }}
          >
            <Candidati data={_candidati} delete={remCandidato}></Candidati>
          </div>
          <hr />
        </div>
        <div className="col-lg-12 form-group">
          <a className="btn btn-block btn-social btn-default">
            <i className="fa fa-send"></i> Invio Candidature
          </a>
        </div>
      </form>
    );
  else
    return (
      <div style={{ textAlign: "center", color: "gray" }}>
        <i className="fa fa-frown-o fa-3x" aria-hidden="true" />
        <h4>Per procedere con l'invio devi selezionare almeno un candidato</h4>
      </div>
    );
}
class Candidati extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.data.map((item, index) => (
          <span key={item.Id} className="badge bg-default text-capitalize" onClick={()=>{this.props.delete(item.Id)}}>
            {(item.Nome + " " + item.Cognome + " ").toLowerCase()}
            <i className="fa fa-trash"></i>
          </span>
        ))}
      </React.Fragment>
    );
  }
}
FormInvio.propTypes = {
  crudStore: PropTypes.object
};
Iform.propTypes = {
  store: PropTypes.object
};
export default FormInvio;
