import React, { Component } from "react";
import PropTypes from "prop-types";
import Loading from "../utils/Loading";
import Stat from "../components/MiniStat"
//const path = process.env.PUBLIC_URL + "/img/";


class Stats extends Component {

  render() {
    const store = this.props.crudStore;
    let spinner = "";
    /*if (store.loadingList)
      spinner = (
        <div className="overlay ">
          <Loading />
        </div>
      );
      */
    return (
      <section className="content">
        <div className="box box-default">
          <div className="box-header with-border">
            <h4 className="box-title">Dash HOME</h4>
          </div>
          <div className="box-body">
            <div className="row">
            <div className="col-md-1"></div>
              <div className="col-md-3">
              <Stat color={"green"} value="60%" title="Mad rate"/>
              </div>
              <div className="col-md-3">
              <Stat color={"red"} value="20%" title=" rate"/>
              </div>
              <div className="col-md-3">
              <Stat color={"yellow"} value="1200" title="Candidate hint" img="fa fa-user-plus"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Stats.propTypes = {
  crudStore: PropTypes.object
};

export default Stats;
