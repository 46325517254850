import { Api, Notify } from "../agent";

import { observable, computed, action } from "mobx";

class CrudStore {
  @observable message = undefined;
  @observable error = undefined;
  @observable loadingList = true;
  @observable show = true;
  @observable showDetail = false;
  classi = [];
  categorie = [];
  titoli = [];
  candidati = [];
  candidato = {};
  form = { Classi: [], Titoli: [], Candidati: [] };

  @action
  changeProps = (prop, value) => {
    if (value != null) this.form[prop] = value.map(val => val.value);
  };
  @action
  enableLoadinglist = () => {
    this.loadingList = false;
  };
  @action
  disableLoadinglist = () => {
    this.loadingList = true;
  };
  @action
  onShow = () => {
    this.form["Candidati"] =this.candidati.filter(item => item.checked === true);
    this.show = !this.show;
  };
  @action
  onClearSelect = () => {
    this.show = !this.show;
  };
  @action
  findCandidato = id => {
    this.showDetail=false
    this.candidato= this.candidati.find(item => item.Id === id);
    console.log(this.candidato)
    this.showDetail=true
  };
  @action
  onSelectProps = (checked, id) => {
   // const elem = this.candidati.find(item => item.Id === id);
    this.candidati=this.candidati.map(item => {
      if(item.Id === id)
      item.checked=checked; 
      return item});
    //elem.checked=checked
    //if (checked) this.form["Candidati"].push(elem);
    //else this.form["Candidati"] = this.form["Candidati"].filter(elem => elem.Id !== id);
    //this.show = !this.show;
    debugger;
  };
  @action
  removeCandidato = ( id) => {
    debugger;
    this.form["Candidati"] = this.form["Candidati"].filter(elem => elem.Id !== id);
  };

  @action
  onSelectAll = (checked) => {
    this.candidati=this.candidati.map(item => {
      item.checked=checked; 
      console.log(item)
      return item});
  //  if (checked) {
  //    this.form["Candidati"]=this.candidati;
  //  }
  //  else this.form["Candidati"] = [];
    //this.show = !this.show;
  };
  @action
  getAllEntity = async base => {
    this.loadingList = true;
    try {
      const _classi = Api.GET("/api/Classi");
      const _categorie = Api.GET("/api/Categorie");
      const _titoli = Api.GET("/api/TitoliPerCategoria/2");
      this.classi = await _classi;
      this.categorie = await _categorie;
      this.titoli = await _titoli;
    } catch (e) {
      this.error = "Errore durante....";
      throw e;
    } finally {
      this.loadingList = false;
    }
  };
  @action
  getAllSTitoli = async cat => {
    this.loadingList = true;
    try {
      this.sTitoli = await Api.GET("/api/TitoliPerCategoria/" + cat);
      this.loadingList = false;
    } catch (e) {
      this.loadingList = false;
      this.error = "Errore durante....";
    }
  };
  @action
  search = async () => {
    this.loadingList = true;
    try {
      const ret = await Api.POST("/api/Candidati", this.form);
      this.candidati = ret.map(item => {
        item.NomeCompleto = item.Nome + " " + item.Cognome;
        item.checked=false
        return item;
      });
      this.loadingList = false;
    } catch (e) {
      this.loadingList = false;
      this.error = "Errore durante....";
    }
  };
}

export default new CrudStore();
