import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import Settings from "../config/Settings";

@inject("authStore")
@observer
class Infos extends Component {
  logoff = () => {
    this.props.authStore.logoff();
  };
  render() {
    return (
      <React.Fragment>
        <div>
          <address >
            <p className="text-center">
              <strong>{Settings.studio}</strong>
            </p>
            {Settings.indirizzo}
            <p >
              <a style={{color:"#333"}} href="mailto:info@NotizieSullaScuola.it">
                <i className="fa fa-envelope"></i>
                <span> info@NotizieSullaScuola.it</span>
              </a>
            </p><p>
              <a style={{color:"#333"}} href="mailto:assistenza@NotizieSullaScuola.it">
                <i className="fa fa-envelope"></i>
                <span> assistenza@NotizieSullaScuola.it</span>
              </a>
            </p>
          </address>
        </div>
      </React.Fragment>
    );
  }
}
Infos.propTypes = {
  authStore: PropTypes.object
};
export default Infos;
