import React, { Component } from "react";

export default class Stat extends Component {
  render() {
    return (
      <div className={"small-box bg-"+this.props.color}>
        <div className="inner">
          <h3>
            {this.props.value}<sup style={{fontSize: 20}}></sup>
          </h3>
          <p>{this.props.title}</p>
        </div>
        <div className="icon">
          <i className={this.props.img||"fa fa-bar-chart"}></i>
        </div>
        <a href="#" className="small-box-footer">
           info <i className="fa fa-arrow-circle-right"></i>
        </a>
      </div>
    );
  }
}
