import React, { Component } from "react";
import PropTypes from "prop-types";
class FooterSection extends Component {
  render() {
    const visible = this.props.visible ? "visible" : "hidden";
    return (
      <footer className={"main-footer " + visible}>
        <div className="pull-right hidden-xs">
          <br />
        </div>
        <strong>Copyright &copy; 2017</strong> <span>All rights reserved.</span>
      </footer>
    );
  }
}
FooterSection.propTypes = {
  visible: PropTypes.bool
};

export default FooterSection;
