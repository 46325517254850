import React, { Component } from "react";
import { useTable, useGroupBy, useFilters, useSortBy, useExpanded, usePagination, useGlobalFilter } from "react-table";

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;

  return (
    <div className="input-group col-md-6 col-md-offset-6">
      <input
        type="text"
        className="form-control"
        value={globalFilter || ""}
        onChange={e => {
          setGlobalFilter(e.target.value || undefined);
        }}
        placeholder={`${count} records...`}
      />
      <div className="input-group-addon">
        <i className="fa fa-search"></i>
      </div>
    </div>
  );
}

function Table({ columns, elems, renderRowSubComponent }) {
  const [data, setData] = React.useState(elems);
  React.useEffect(() => {
    console.log(elems)
    setData(elems);
  }, [elems]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    visibleColumns,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex, globalFilter },
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: ["Nome", "Cognome", "Email", "Tel", "IndirizzoResidenza","checked"] } },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );
  if (data.length == 0)
    return (
      <div style={{ textAlign: "center", color: "gray" }}>
        <i className="fa fa-frown-o fa-3x" aria-hidden="true" />
        <h3>Nessun Candidato Trovato</h3>
      </div>
    );
  return (
    <>
      <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
      <br/>
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // eslint-disable-next-line react/jsx-key
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <span>
                    {column.render("Header")} {column.isSorted ? column.isSortedDesc ? <i className="fa fa-sort-alpha-asc"></i> : <i className="fa fa-sort-alpha-desc"></i> : null}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
                {row.isExpanded ? (
                  <tr style={{ backgroundColor: "#c8ccd6", color: "white" }}>
                    <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                  </tr>
                ) : null}
              </>
            );
          })}
        </tbody>
      </table>
      <nav>
        <ul className="pager">
          <li className="previous">
            <a style={{ color: "#111111" }} onClick={() => previousPage()} disabled={!canPreviousPage}>
              <span>&larr;</span>
              {"Prev"}
            </a>
          </li>
          <span>
            {"Page"}
            <strong style={{ color: "#111111" }}>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <li className="next">
            <a onClick={() => nextPage()} disabled={!canNextPage} style={{ color: "#111111" }}>
              {"Next"}
              <span>&rarr;</span>
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}
export default Table;
