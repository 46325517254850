import React, { Component, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { FormRicerca, FormInvio } from "../forms/index";
import DettPrint from "../components/DetailItemPrint";
import Loading from "../utils/Loading";
import { inject, observer } from "mobx-react";
import Modal from "../components/ModalGeneric";
import Table from "../components/Table";

@inject("entityStore")
@observer
class GestMad extends Component {
  state = {
    show: false,
    all:false,
    columns: [
      {
        Header: "Nome",
        accessor: "Nome",
      },
      {
        Header: "Cognome",
        accessor: "Cognome",
      },
      {
        Header: "Email",
        accessor: "Email",
      },
      {
        Header: "Tel",
        accessor: "Tel",
      },
      {
        Header: "IndirizzoResidenza",
        accessor: "IndirizzoResidenza",
      },
      {
        Header: "checked",
        accessor: "checked",
      },
      
      {
        isSorted: false,
        Header: () => (
          <label className="control-label checkbox">
          <input type="checkbox" onChange={(value)=>{this.selectAll(value.target.checked)}} />
          <span className="checkmark"></span>
        </label>
        ),
        accessor: "Id",
        sort:false,
        Cell: ({ row }) => <Seleziona value={row.values.Id} store={this.props.entityStore} checked={row.values.checked} onCheck={()=>{this.setState({all:!this.state.all})}}></Seleziona>,
      },
      {
        Header: "Candidato",
        accessor: "NomeCompleto",
      },

      {
        Header: "Classi Concorso",
        accessor: "Classi",
        Cell: ({ cell: { value } }) => <Classi data={value} index={1} serched={this.props.entityStore.form.Classi}></Classi>,
      },
      {
        Header: "Titoli Concorso",
        accessor: "Complementari",
        Cell: ({ cell: { value } }) => <Complementari data={value} index={1}></Complementari>,
      },
      {
        Header: "Comune Residenza",
        accessor: "ComuneResidenza",
      },
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander",
        Cell: ({ row }) => <span {...row.getToggleRowExpandedProps()}>{!row.isExpanded ? <i className="fa fa-compress fa-2x"></i> : <i className="fa fa-expand fa-2x"></i>}</span>,
      },
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "print",
        Cell: ({ row }) => (
          <span onClick={() => this.openModal(row.values.Id)}>
            <i className="fa fa-print fa-2x"></i>
          </span>
        ),
      },
    ],
  };
  selectAll(value){
    debugger;
    this.props.entityStore.onSelectAll(value)
    this.setState({all:!this.state.all})
  }
  openModal = (id) => {
    const open = !this.state.show;
    if (open) this.props.entityStore.findCandidato(id);

    this.setState({ show: !this.state.show });
  };
  print = () => {
    var doc = new jsPDF();
    const data = this.props.entityStore.candidato;
    /*
    let y = 20;
    doc.setFont("times");
    doc.setFontStyle("bold");

    doc.setLineWidth(0.1);
    doc.text(data.Nome + " " + data.Cognome, 105, y, null, null, "center");

    doc.setFontStyle("normal");
    let str = ("Telefono: " + data.Tel + " Email: " + data.Email + " Indirizzo: " + data.IndirizzoResidenza).replace(/undefined/g, "").replace(/null/g, "");
    y += 10;
    doc.text(str, 10, y);
    y += 10;
    doc.setFontStyle("bold");
    doc.text("Titoli Classi di Concorso", 10, (y += 10));
    y += 5;
    doc.line(10, y, 200, y);
    doc.setFontStyle("normal");
    y += 10;
    let x=10;
    data.Classi.map((item, i) => {
      doc.text(item.Nome, x, y);
      x+=20;
    });
    
    y += 10;
    doc.setFontStyle("bold");
    doc.text("Titoli di Studio", 10, (y += 10));
    y += 5;
    doc.line(10, y, 200, y);
    doc.setFontStyle("normal");
    data.Titoli.map((item, i) => {
      y += 10;
      str = item.Nome + " " + item.Data + " " + item.Voto + " " + item.Luogo + " " + item.Categoria;
      doc.text(str, 10, y);
     
    });
    y += 10;
    doc.setFontStyle("bold");
    doc.text("Titoli Candidato", 10, y );
    y += 5;
    doc.line(10, y, 200, y);
    doc.setFontStyle("normal");
    data.Complementari.map((item, i) => {
      y += 10;
      doc.text(item.StrTipo, 10, y);
     
    });
*/
    // Set the document to automatically print via JS
    doc.fromHTML(this.inputElement, 1, 1)
    doc.autoPrint();
    window.open(doc.output("bloburl"), "_blank");
  };
  componentDidMount = () => {};
  renderRowSubComponent = (row) => <ExpandRowItem data={row.row.values} />;
  render() {
    const store = this.props.entityStore;
    let spinner = "";

    if (store.loadingList)
      spinner = (
        <div className="overlay">
          <Loading />
        </div>
      );
    return (
      <section className="content">
        <div className="box box-default">
          <div className="box-header with-border">
            <h4 className="box-title">Ricerca MAD</h4>
          </div>
          <div className="box-body">
            <div className="row">
              <FormRicerca />
            </div>
            <div className="row">
              <Header size={store.candidati.length} store={store} />
              <div className="col-md-12">
                <hr />
              </div>
              <div className="col-md-12 ">
                <div className="table-responsive">
                  <Table columns={this.state.columns} elems={store.candidati} renderRowSubComponent={this.renderRowSubComponent} />
                </div>
              </div>
            </div>
          </div>
          {spinner}
        </div>
        <Modal
          component={<DettPrint  inputRef={el => this.inputElement = el}/>}
          button={
            <button type="button" className="btn  btn-default" onClick={this.print}>
              <i className="fa fa-print"></i>
              &nbsp;Print
            </button>
          }
          title="Dettaglio Candidato"
          show={this.state.show}
          closeModal={this.openModal}
        />
      </section>
    );
  }
}

function Header({ size ,store}) {
  const [show, setShow] = useState(false);
  const openModal = () => {
    store.onShow()
    setShow(true)
  };
  const closeModal = () => setShow(false);
  return (
    <>
      <Modal component={<FormInvio />} button={null} title="Invio Candidature MAD" show={show} closeModal={closeModal} />
      <div className="col-md-9">
        <h4>
          <strong>{size} Elementi Trovati</strong>
        </h4>
      </div>

      <div className="col-md-3">
        <button type="button" className="btn btn-block btn-default" onClick={openModal}>
          <i className="fa fa-send"></i>
          &nbsp;Invia Candidature
        </button>
      </div>
    </>
  );
}

function ExpandRowItem({ data }) {
  return (
    <div className="row">
      <div className="col-md-2">
        <div className="pull-left image">
          <i className="fa fa-user fa-3x"></i>
        </div>
      </div>
      <div className="col-md-8">
        <strong>
          {("Candidato: " + data.Nome + " " + data.Cognome + " " + data.Tel + " " + data.Email + " " + data.IndirizzoResidenza).replace(/undefined/g, "").replace(/null/g, "")}
        </strong>
      </div>
      <div className="col-md-2 text-right">
        <i className="fa fa-paperclip fa-2x"></i>
      </div>
      <div className="col-md-12">
        <hr></hr>
      </div>
      <div className="col-md-2">Dettagli</div>
      <div className="col-md-2">
        <strong>{"Classi Concorso "}</strong>
        <Classi data={data.Classi} serched={[]}></Classi>
      </div>
      <div className="col-md-8">
        <strong>{"Titoli Concorso "}</strong>
        <Complementari data={data.Complementari}></Complementari>
      </div>
    </div>
  );
}
function Seleziona({ value, store,checked,onCheck}) {
  const change = (e) => {
    store.onSelectProps(e.target.checked, value);
    _setChecked(e.target.checked)
    onCheck()
  };
  const [_checked,_setChecked]=useState(checked)
  useMemo(() => _setChecked(checked), [checked]);
  console.log("sel "+checked+" id "+value)
  return (
    <label className="control-label checkbox">
      <input type="checkbox" onChange={change} value={value} checked={_checked}></input>
      <span className="checkmark"></span>
    </label>
  );
}
function Classi({ data, serched }) {
  if (serched.length > 0)
    return (
      <>
        {data.map((item) =>
          serched.indexOf("" + item.Id) != -1 ? (
            <div key={item.Id}>
              <span className="label bg-black">{item.Nome}</span>&nbsp;
            </div>
          ) : null
        )}
      </>
    );
  return (
    <div className="row">
      {data.map((item) => (
        <div key={item.Id} className="col-md-4">
          <span className="label bg-black">{item.Nome}</span>&nbsp;
        </div>
      ))}
    </div>
  );
}
function Titoli(data) {
  return (
    <>
      {data.map((item, index) => (
        <div key={item.Titolo.Id}>
          <span className="label bg-orange">{item.Titolo.Descrizione}</span>
          &nbsp;
        </div>
      ))}
    </>
  );
}
function Complementari({ data }) {
  return (
    <>
      {data.map((item) => (
        <div key={item.Tipo}>
          <span className="label bg-orange" data-toggle="tooltip" tooltip={item.nome}>
            {item.StrTipo}
          </span>
          &nbsp;
        </div>
      ))}
    </>
  );
}

GestMad.propTypes = {
  crudStore: PropTypes.object,
};

export default GestMad;
