import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import 'bootstrap/dist/css/bootstrap.min.css'
import './StyleAnimation.min.css'
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'font-awesome/css/font-awesome.min.css';
import App from "./App";
import { authStore, commonStore, userStore, notifyStore, entityStore } from "./store";
import * as serviceWorker from "./serviceWorker";

const stores = {
  authStore,
  entityStore,
  commonStore,
  userStore,
  notifyStore
};

const app = (
  <Provider {...stores}>
    <App />
  </Provider>
);

window._____APP_STATE_____ = stores;
ReactDOM.render(app, document.getElementById("root"));
serviceWorker.register();
