import React from "react";
import PropTypes from "prop-types";
import Settings from "../config/Settings";
import UserInfo from "../components/UserInfo";
import history from "../history";
import MNotifiche from "./MenuNotifiche";
import MenuAggior from "./MenuAggior";
class HeaderBar extends React.Component {
  state = {
    mAgg: false,
    mNotif: false,
    mUser: false
  };
  handlerClick = () => {
    const body = document.getElementsByTagName("body")[0];
    if (body.className.indexOf("sidebar-collapse") !== -1) body.className = body.className.replace("sidebar-collapse sidebar-open", "");
    else body.className = body.className + " sidebar-collapse sidebar-open";
  };
  linkApp = () => {
    history.push("/home");
  };
  linkCf = () => {
    history.push("/config");
  };
  linkSearch = () => {
    history.push("/search");
  };
  toogle = elem => {
    const obj = this.state;
    for (const prop in obj) {
      if (prop == elem && obj[elem] == false) obj[elem] = true;
      else obj[prop] = false;
    }
    this.setState(obj)
  };
  render() {
    const visible = this.props.visible ? "visible" : "hidden";
    return (
      <header className={"main-header " + visible}>
        <div className="logo navbar-fixed-top">
          <span className="logo-mini">
            <b>M.</b>ad
          </span>
          <span className="logo-lg">
            <b>{Settings.studio}</b>
          </span>
        </div>
        <nav className="navbar navbar-fixed-top">
          <div className="navbar-custom-menu" style={{ float: "left" }}>
            <ul className="nav navbar-nav"></ul>
          </div>
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
              <li className="dropdown messages-menu">
                <a onClick={this.linkCf} className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                  <i className="fa fa-cog" />
                </a>
              </li>
              <li onClick={this.linkApp} className="dropdown messages-menu">
                <a className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                  <i className="fa fa-home" />
                </a>
              </li>
              <li onClick={this.linkSearch} className="dropdown messages-menu">
                <a className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                  <i className="fa fa-search" />
                </a>
              </li>
              <MenuAggior ref={div => (this.mAgg = div)} open={this.state.mAgg} onClick={() => this.toogle("mAgg")} />
              <MNotifiche ref={div => (this.mNotif = div)} open={this.state.mNotif} onClick={() => this.toogle("mNotif")} />
              <UserInfo ref={div => (this.mUser = div)} open={this.state.mUser} onClick={() => this.toogle("mUser")} />
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

HeaderBar.propTypes = {
  visible: PropTypes.bool
};
export default HeaderBar;

/*
 <li className="dropdown messages-menu">
                <a onClick={this.linkCf} className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                  <i className="fa fa-cog" />
                </a>
              </li>
              <li onClick={this.linkApp} className="dropdown messages-menu">
                <a className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                  <i className="fa fa-calendar-check-o" />
                </a>
              </li>
*/
