import React, { Component } from "react";
import { Link } from "react-router-dom";
import Settings from "../config/Settings";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";

@inject("authStore")
@observer
class UserInfo extends Component {
  state = { open: "" };
  logoff = () => {
    this.props.authStore.logout();
  };
  open = () => {
    if (this.state.open === "") this.setState({ open: "open" });
    else this.setState({ open: "" });
  };

  render() {
    return (
      <li className={"dropdown user user-menu " + (this.props.open?"open":"")}>
        <a className="dropdown-toggle " data-toggle="dropdown" onClick={this.props.onClick}>
          <img src={process.env.PUBLIC_URL + Settings.img.vuoto} className="user-image" aria-hidden alt="User Image" />
          <span className="hidden-xs">
            <b>{Settings.name}</b>
          </span>
        </a>
        <ul className="dropdown-menu">
          <li className="user-header" style={{ backgroundColor: Settings.color, opacity: 0.8 }}>
            <img src={process.env.PUBLIC_URL + Settings.img.vuoto} className="img-circle" aria-hidden alt="User Image" />
            <p>
              {Settings.studio}
            </p>
          </li>
          <li className="user-footer">
            <div className="pull-left">
              <Link className="btn btn-default btn-flat" to="profile">
                Profile
              </Link>
            </div>
            <div className="pull-right">
              <button className="btn btn-default btn-flat" onClick={this.logoff}>
                <i className="fa fa-sign-out" />
                Esci
              </button>
            </div>
          </li>
        </ul>
      </li>
    );
  }
}
UserInfo.propTypes = {
  authStore: PropTypes.object
};
export default UserInfo;
