import React, { useState } from "react";

export default function Config(props) {
  const [count, setCount] = useState(0);

  return (
    <section className="content">
    <div className="box box-default">
      <div className="box-header with-border">
        <h4 className="box-title">Configurazione </h4>
      </div>
      <div className="box-body">
        <div className="row">
          <div className="col-md-12">
          </div>
          <div className="col-md-12">
            <div style={{ textAlign: "center", color: "gray" }}>
              <h3>Benvenuti!</h3>
              <i className="fa fa-handshake-o fa-4x"></i>
              <p> di seguito potete accedere nell\'area per la ricerca dei candidati presente</p>
              <p> per iniziare clicca il link ricerca</p>
              <h4>
                
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}
