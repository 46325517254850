import authStore from "./store/authStore";
import commonStore from "./store/commonStore";
import Notify from "./store/notifyStore";
let API_ROOT = HOME_WS+""; 
console.log(HOME_WS)
const handleErrors = (status, res) => {
  if (status === 401) {
    authStore.logout();
    throw "Autentication is falied";
  } else {
    throw res;
  }
};

const responseBody = async (res) => {
  if (res.ok) {
    const _res = await res.json();

    if (_res.error) {
      Notify.notify("ERROR", _res.error);
      throw _res.error;
    } else return _res;
  } else {
    const status = res.status;
    const err = await res.text();
    handleErrors(status, err);
  }
};

const myHeaders = () => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Authorization: "Bearer " + commonStore.getToken(),
    "X-Auth-Token": commonStore.getToken()
  };
  return headers;
};

const myHeadersUplod = () => {
  const headers = {
    // 'Accept':'application/json',
    //'Content-Type': undefined,

    //  'content-type': 'multipart/form-data',
    //  'Cache-Control': 'no-cache',
    "X-Auth-Token": commonStore.getToken()
  };
  return headers;
};

const Header = (method, body) => {
  const opt = {
    method: method,
    headers: myHeaders(),
    mode: "cors",
    body: serialize(body || {}),
    cache: "default"
  };
  if (method === "GET") {
    delete opt.body;
  }
  return opt;
};
const Header2 = (method, body) => {
  const opt = {
    method: method,
    headers: myHeaders(),
    mode: "cors",
    body: JSON.stringify(body || {}),
    cache: "default"
  };
  if (method === "GET") {
    delete opt.body;
  }
  return opt;
};
const HeaderOTH = (method, body) => {
  const opt = Header(method, body);
  opt.headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
  };
  return opt;
};

const HUpload = (method, body) => {
  const opt = {
    method: method,
    headers: myHeadersUplod(),
    // mode: 'no cors',
    body: body
    //  cache: 'default'
  };
  if (method === "GET") {
    delete opt.body;
  }
  return opt;
};
const serialize = obj => {
  if (obj === undefined) return "";
  else
    return Object.keys(obj)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]))
      .join("&");
};

const requests = {
  del: (url, body) =>
    fetch(`${API_ROOT}${url}`, Header("Delete", body))
      .then(responseBody)
      .catch(handleErrors),
  get: (url, qs) =>
    fetch(`${API_ROOT}${url}${"?"}${serialize(qs)}`, Header("GET"))
      .then(responseBody)
      .catch(handleErrors),
  put: (url, body) =>
    fetch(`${API_ROOT}${url}`, Header("PUT", body))
      .then(responseBody)
      .catch(handleErrors),
  post: (url, body) =>
    fetch(`${API_ROOT}${url}`, Header("POST", body))
      .then(responseBody)
      .catch(handleErrors),
  post2: (url, body) =>
    fetch(`${API_ROOT}${url}`, Header2("POST", body))
      .then(responseBody)
      .catch(handleErrors),
  upload: (url, body) =>
    fetch(`${API_ROOT}${url}`, HUpload("POST", body))
      .then(responseBody)
      .catch(handleErrors),
  outh: (url, body) =>
    fetch(`${API_ROOT}${url}`, HeaderOTH("POST", body))
      .then(responseBody)
      .catch(handleErrors)
};

const Auth = {
  current: () => requests.get("/user"),
  login: (email, password) =>
    requests.outh("/token", {
      username: email,
      password: password,
      grant_type: "password"
    }),
  register: (username, email, password) => requests.post("/register", { user: { username, email, password } }),
  logout: () => requests.post("/logout", { token: commonStore.getToken() }),
  isAuth: () => requests.get("/api/Classi"),
  onProfile: () => requests.get("/profile"),
  test: () => requests.get("/api/Classi"),
  onReset: elem => requests.post("/resetdsk", elem)
};

const Api = {
  //POST: (url, body) => requests.post(url, body),
  POST: (url, body) => requests.post2(url, body),
  GET: (url, body) => requests.get(url, body),
  PUT: (url, body) => requests.put(url, body),
  DELETE: (url, body) => requests.delete(url, body),
  UPLOAD: (url, body) => requests.delete(url, body)
};
export { Auth, Api, Notify };
