import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Settings from "../config/Settings";
//import { inject, observer } from "mobx-react";

//@inject("notificationStore")
//@observer
class MNotifiche extends Component {
  list = () => {};
  state = { open: "" };
  logoff = () => {
    this.props.authStore.logout();
  };
  open = () => {
    if (this.state.open === "") this.setState({ open: "open" });
    else this.setState({ open: "" });
  };
  render() {
    const data = [{ titolo: "Messaggio",sms: "ricezione sms danilo", data_ricezione: moment() }];
    return (
      <li className={"dropdown messages-menu " + (this.props.open?"open":"")}>
      <a className="dropdown-toggle " data-toggle="dropdown" onClick={this.props.onClick}>
      <i className="fa fa-bell" />
          <span className="label label-warning">{data.length}</span>
        </a>
        <ul className="dropdown-menu">
          <li className="header">Notifiche</li>
          <li>
            <div className="slimScrollDiv" style={{ position: "relative", width: "auto" }}>
              <ListStatus data={data} />
            </div>
          </li>
          <li className="footer" onClick={this.cancell}>
            <a>
              <b>Cancella</b>
            </a>
          </li>
        </ul>
      </li>
    );
  }
}

function ListStatus(props) {
  const elems = props.data;
  let listItems;
  if (elems.length > 0) {
    listItems = elems.map((elem, index) => <Status key={index} data={elem} />);
    return (
      <ul className="menu" style={{ overflow: "scroll", width: "100%", height: 300 }}>
        {listItems}
      </ul>
    );
  }
  return <p className="text-center">Nessun Elemento</p>;
}
class Status extends Component {
  render() {
    return (
      <li>
        <a href="#">
          <div className="pull-left">
          <i className ="fa fa-bell-o fa-2x" style={{color:"#c6217f"}}/>
          </div>
          <h4>
          {this.props.data.titolo}
            <small>
              <i className="fa fa-clock-o"></i> {this.props.data.data_ricezione.format("ll")}
            </small>
          </h4>
          <p> {this.props.data.sms}</p>
        </a>
      </li>
    );
  }
}

MNotifiche.propTypes = {
  notificationStore: PropTypes.object
};
Status.propTypes = {
  data: PropTypes.object.isRequired
};

ListStatus.propTypes = {
  data: PropTypes.array.isRequired
};
export default MNotifiche;
