import React, { Component } from "react";
import { inject, observer } from "mobx-react";
@inject("notifyStore")
@observer
class NotifyComp extends Component {
  render() {
    const store = this.props.notifyStore;
    switch (store.type) {
      case "ERROR":
        return (
          <div className="alert alert-danger" role="alert">
            <strong>Attenzione!</strong> {store.message}
          </div>
        );
      case "INFO":
        return (
          <div className="alert alert-info" role="alert">
            <strong>Informazione!</strong> {store.message}
          </div>
        );
      case "SUCCESS":
        return (
          <div className="alert alert-success" role="alert">
            <strong>Ben Fatto!</strong> {store.message}
          </div>
        );
      case "WARN":
        return (
          <div className="alert alert-warning" role="alert">
            <strong>Avviso!</strong> {store.message}
          </div>
        );
      default:
        return <div />;
    }
  }
}
export default NotifyComp;
