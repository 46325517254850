import React from "react";
import PropTypes from "prop-types";
import HeaderBarSection from "./HeaderSection";
import FooterSection from "./FooterSection";
import Settings from "../config/Settings";
import NotifyComp from "../components/NotifyComp";
class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { component: props.component, styles: props.styles };
  }

  render() {
    const style = { backgroundColor: Settings.color };
    const style1 = { backgroundColor: Settings.color, paddingTop: 40 };
    return (
      <div className="wrapper" style={this.props.sideBar ? { paddingTop: 40 } : style1}>
        <HeaderBarSection visible={this.props.header} />
         <div className={this.props.sideBar ? "content-wrapper" : ""} style={this.props.sideBar ? style : {}}>
          <div style={{ paddingTop: 10 }}>
            <NotifyComp />
          </div>
          {this.state.component}
        </div>
        <FooterSection visible={this.props.footer} />
      </div>
    );
  }
}

Layout.defaultProps = {
  header: true,
  sideBar: false,
  footer: true,
  styles: ""
};

Layout.propTypes = {
  header: PropTypes.bool,
  sideBar: PropTypes.bool,
  footer: PropTypes.bool,
  component: PropTypes.element.isRequired,
  styles: PropTypes.string,
  status: PropTypes.string
};
export default Layout;
