import React, { Component } from "react";
import PropTypes from "prop-types";
import Settings from "../config/Settings";
class Logo extends Component {
  render() {
    return (
      <div
        className="login-logo"
        style={{ marginBottom: 0, alignItems: "center" }}
      >
        <img
          src={process.env.PUBLIC_URL + Settings.img.logo}
          aria-hidden
          alt="User Image"
          width={this.props.width ? this.props.width : "60%"}
          className="img-rounded"
        />
      </div>
    );
  }
}

export default Logo;

Logo.propTypes = {
  width: PropTypes.string
};
